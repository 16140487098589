/* @flow */
import {
    getInputs, getDrivers, getDeviceSerial, getFuelCardAccounts, getWexFuelCardAccounts,
    getFleetCorFuelCardAccounts, getFuelCards, getFleetCorFuelCards, getWexFuelCards,
} from '../../helper-classes/utility-functions';
import { getAssetGroups, getAssetTags } from '../Maintenance/helper-classes/common-services';
import dropdownNested from '../../assets/icons/dropdown-nested.svg';

export const commonFilterData = {
    filterType: 'select',
    filterTitle: '[Input Name]',
    datumValueParam: 'highName',
    datumLabelParam: 'highName',
    selectedOptions: [],
    dataArrayParam: 'data',
    dataCountParam: 'max',
    propertyType: 'Long',
    disableSearch: false,
    showSelection: true,
    disableChips: false,
    perPage: 25,
    dataTotalParam: 'total',
    selectStyles: null,
    renderEmpty: true,
};

export const accountNumFilter = {
    ...commonFilterData,
    filterOptions: getFuelCardAccounts,
    filterTitle: 'Account #',
    property: 'fuelCardAccountId',
    label: 'fuelCardAccountId',
    datumValueParam: 'fcAccount',
    datumLabelParam: 'fcAccount',
};


export const wexAccountNumFilter = {
    ...commonFilterData,
    filterOptions: getWexFuelCardAccounts,
    filterTitle: 'Account #',
    property: 'fuelCardAccountId',
    label: 'fuelCardAccountId',
    datumValueParam: 'wexFuelCardAccountId',
    datumLabelParam: 'wexFuelCardAccountId',
};

export const fleetCorAccountNumFilter = {
    ...commonFilterData,
    filterOptions: getFleetCorFuelCardAccounts,
    filterTitle: 'Account Code',
    property: 'fuelCardAccountId',
    label: 'fuelCardAccountId',
    datumValueParam: 'fleetCorAccountCode',
    datumLabelParam: 'fleetCorAccountCode',
};

export const fuelCardInput = { id: '' };
export const fuelCardFilter = {
    ...commonFilterData,
    filterOptions: () => getFuelCards(fuelCardInput.id),
    filterTitle: 'Fuel Card',
    property: 'fuelCardId',
    label: 'fuelCardNumber',
    datumValueParam: 'id',
    datumLabelParam: 'cardNumber',
};

export const wexFuelCardFilter = {
    ...commonFilterData,
    filterOptions: () => getWexFuelCards(fuelCardInput.id),
    filterTitle: 'Fuel Card',
    property: 'fuelCardId',
    label: 'fuelCardNumber',
    datumValueParam: 'wexFuelCardNumber',
    datumLabelParam: 'wexFuelCardNumber',
};

export const fleetCorFuelCardFilter = {
    ...commonFilterData,
    filterOptions: () => getFleetCorFuelCards(fuelCardInput.id),
    filterTitle: 'FleetCor Vehicle ID',
    property: 'fuelCardId',
    label: 'fuelCardNumber',
    datumValueParam: 'fleetCorVehicleId',
    datumLabelParam: 'fleetCorVehicleId',
};

export const deviceSerialFilter = {
    ...commonFilterData,
    filterOptionsWithParams: getDeviceSerial,
    filterTitle: 'Device Serial',
    property: 'deviceSerialNumber',
    label: 'deviceSerialNumber',
    datumValueParam: 'id',
    datumLabelParam: 'serialNumber',
};

export const driversFilter = {
    ...commonFilterData,
    filterOptionsWithParams: getDrivers,
    filterTitle: 'Driver',
    property: 'operatorId',
    label: 'name',
    datumValueParam: 'id',
    datumLabelParam: 'name',
};

export const tagsFilter = {
    ...commonFilterData,
    filterOptions: getAssetTags,
    filterTitle: 'Tags',
    property: 'selectedTag',
    label: 'tags',
    datumValueParam: 'tag',
    datumLabelParam: 'tag',
};

export const inputsFilter1 = {
    ...commonFilterData,
    filterOptionsWithParams: getInputs,
    property: 'input1HighName',
    label: 'input1HighName',
};

export const inputsFilter2 = {
    ...commonFilterData,
    filterOptionsWithParams: getInputs,
    property: 'input2HighName',
    label: 'input2HighName',
};

export const inputsFilter3 = {
    ...commonFilterData,
    filterOptionsWithParams: getInputs,
    property: 'input3HighName',
    label: 'input3HighName',
};

export const inputsFilter4 = {
    ...commonFilterData,
    filterOptionsWithParams: getInputs,
    property: 'input4HighName',
    label: 'input4HighName',
};

export const assetGroupFilter = () => ({
    ...commonFilterData,
    filterOptions: getAssetGroups,
    filterTitle: 'Asset Group',
    property: 'groupId',
    label: 'groupName',
    datumValueParam: 'id',
    datumLabelParam: 'name',
    isMulti: false,
    disableSearch: true,
    selectStyles: {
        option: (s: Object, { data } : Object) => {
            const obj = data.data || {};
            let newStyle = { ...s };
            if (obj.nestLevel) {
                newStyle = {
                    ...newStyle,
                    marginLeft: 8 + (obj.nestLevel * 16),
                };
            }
            if (obj.childNodes && obj.childNodes.length > 0) {
                newStyle = {
                    ...newStyle,
                    marginLeft: (newStyle.marginLeft || 0) + 6,
                    paddingLeft: 18,
                    backgroundImage: `url(${dropdownNested})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left center',
                };
            }
            return newStyle;
        },
    },
    showToolTip: true,
});

export const defaultInputs = [
    { label: 'Closed', value: 'Closed' },
    { label: 'Open', value: 'Open' },
    { label: 'Off', value: 'Off' },
    { label: 'On', value: 'On' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Active', value: 'Active' },
];

export const systemDefaultInputs = [
    { label: 'Door Open', value: 'Door Open' },
    { label: 'Door Closed', value: 'Door Closed' },
    { label: 'Side Door Open', value: 'Side Door Open' },
    { label: 'Side Door Closed', value: 'Side Door Closed' },
    { label: 'Fridge On', value: 'Fridge On' },
    { label: 'Fridge Off', value: 'Fridge Off' },
];

export const systemInputType = [
    {
        code: 'door.hiOpenLoClosed',
        highName: 'High = Door Open, Low = Door Closed',
        highStateName: 'Door Open',
        lowName: 'High = Door Open, Low = Door Closed',
        lowStateName: 'Door Closed',
        highNameValue: 'Door Open',
        lowNameValue: 'Door Closed',
        type: 'system',
    },
    {
        code: 'door.hiClosedLoOpen',
        highName: 'High = Door Closed, Low = Door Open',
        lowName: 'High = Door Closed, Low = Door Open',
        highStateName: 'Door Closed',
        highNameValue: 'Door Closed',
        lowNameValue: 'Door Open',
        lowStateName: 'Door Open',
        type: 'system',
    },
    {
        code: 'side.hiOpenLoClosed',
        highName: 'High = Side Door Open, Low = Side Door Closed',
        highStateName: 'Side Door Open',
        lowName: 'High = Side Door Open, Low = Side Door Closed',
        lowStateName: 'Side Door Closed',
        highNameValue: 'Side Door Open',
        lowNameValue: 'Side Door Closed',
        type: 'system',
    },
    {
        code: 'side.hiClosedLoOpen',
        highName: 'High = Side Door Closed, Low = Side Door Open',
        highStateName: 'Side Door Closed',
        lowName: 'High = Side Door Closed, Low = Side Door Open',
        highNameValue: 'Side Door Closed',
        lowStateName: 'Side Door Open',
        lowNameValue: 'Side Door Open',
        type: 'system',
    },

    {
        code: 'fridge.hiOnLoOff',
        highName: 'High = Fridge On, Low = Fridge Off',
        highStateName: 'Fridge On',
        lowName: 'High = Fridge On, Low = Fridge Off',
        lowStateName: 'Fridge Off',
        highNameValue: 'Fridge On',
        lowNameValue: 'Fridge Off',
        type: 'system',
    },
    {
        code: 'fridge.hiOffLoOn',
        highName: 'High = Fridge Off, Low = Fridge On',
        highStateName: 'Fridge Off',
        lowName: 'High = Fridge Off, Low = Fridge On',
        highNameValue: 'Fridge Off',
        lowStateName: 'Fridge On',
        lowNameValue: 'Fridge On',
        type: 'system',
    },
]

export const assetIcons = [
    { key: 'Boat', value: 'boat', imagePath: 'boat-green' },
    { key: 'Bus', value: 'bus', imagePath: 'bus-green' },
    { key: 'Car', value: 'car', imagePath: 'car-green' },
    { key: 'Cement Truck', value: 'cement-truck', imagePath: 'cement-truck-green' },
    { key: 'Dump Truck', value: 'dump-truck', imagePath: 'dump-truck-green' },
    { key: 'Excavator', value: 'excavator', imagePath: 'excavator-green' },
    { key: 'Flat Bed', value: 'flat-bed', imagePath: 'flat-bed-green' },
    { key: 'Generator', value: 'generator', imagePath: 'generator-green' },
    { key: 'Motorcycle', value: 'motorcycle', imagePath: 'motorcycle-green' },
    { key: 'Snowmobile', value: 'snowmobile', imagePath: 'snowmobile-green' },
    { key: 'Tractor', value: 'tractor', imagePath: 'tractor-green' },
    { key: 'Trailer', value: 'trailer', imagePath: 'trailer-green' },
    { key: 'Truck', value: 'truck', imagePath: 'truck-green' },
    { key: 'Van', value: 'van', imagePath: 'van-green' },
];

export const styles = {
    header: { position: 'sticky', top: 0, zIndex: 99 },
    clrPicker: { height: 'auto', paddingBottom: '10px !important' },
    hide: { display: 'none !important' },
    lp10px: { paddingLeft: 10 },
    lp30px: { paddingLeft: '30px !important' },
    bp8px: { paddingBottom: 8 },
    trackingDetailTag: { color: '#b0b8bf', width: 200 },
    tableCell: { borderBottom: 0 },
    trackingTitleTag: { width: 200, color: '#b0b8bf' },
    updatesLabel: { color: '#b0b8bf', fontSize: '.75rem', padding: 16 },
    updatesValue: { fontSize: '.75rem', padding: '8px 16px' },
    blankCard: { padding: '13px 26px 26px 26px', marginTop: 10 },
    inputItem: { marginBottom: 16 },
    dateField: { margin: '0 20px 0 20px', width: '100%' },
    input86p: { maxWidth: 350, minWidth: 195, width: '86%' },
    input350px: { width: 350 },
    input100px: { width: 100 },
    dateContainer: { width: 150 },
    clearButton: { width: 70 },
    checkBox: { color: '#007aff !important', paddingLeft: 0 },
    cardDrop: { width: 170, margin: 0 },
    dropdown: {
        flex: 'auto', minWidth: 175, maxWidth: 200, margin: '0 20px 0 0', whiteSpace: 'nowrap',
    },
    clrPickerLbl: {
        borderRadius: 4, fontStyle: 'normal', padding: '0 8px', margin: '0 4px',
    },
    show: {
        display: 'block !important', margin: '17px 16px !important',
    },
    iconBtn: {
        padding: '0 5px 4px',
        backgroundColor: 'transparent !important',
        fontSize: 18,
    },
    radioTitle: {
        opacity: '0.87',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },
    installationUIValues: {
        opacity: '0.87',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
        marginLeft: '26px',
        padding: '2px',
    },
    cardSubTitle: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
        opacity: '0.87',
        padding: 16,
    },
    cardTitle: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },
    installationCardTitle: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        marginBottom: '12px',
        color: '#000000',
    },
    clrPickerLblSpan: {
        color: 'inherit',
        filter: 'invert(1)',
        fontStyle: 'normal',
        background: 'transparent',
        mixBlendMode: 'difference',
    },
    noteTextArea: { width: '100%' },
    vinErrorMessage: {
        position: 'absolute',
        bottom: '-22px',
        left: 15,
        fontSize: '0.97rem',
        color: '#ff0000',
    },
};

export const inlined = {
    estHelpText: { padding: '15px 35px 15px 15px' },
    assetIcon: { marginRight: 30, padding: 0 },
    recOdoGrid: { padding: 16, color: 'rgba(0, 0, 0, 0.87)' },
    recOdoContainer: { marginBottom: 10, fontSize: 15 },
    m020: { margin: '0 20px' },
    m032: { margin: '0 32px' },
    m0206: { margin: '2px 6px' },
    mb10: { marginBottom: 10 },
    ml20: { marginLeft: 20 },
    mt_6: { marginTop: -6 },
    pb0: { paddingBottom: 0 },
    historyRow: { margin: '0px 32px 0 20px' },
    clrBtnContainer: { padding: '30px 0 12px 0' },
    engHrs: { padding: 16, color: 'rgba(0, 0, 0, 0.87)', fontSize: 15 },
    eldCardUL: { margin: '25px 0 5px -35px' },
    safetyCardTbl: { maxWidth: 500, marginLeft: -5, marginTop: 30 },
    headerCell: { padding: '0 0 15px' },
    loaderStyle: { position: 'absolute', top: '50%', left: '40vw' },
    loaderContainer: {
        width: '100%',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.33)',
        position: 'absolute',
        zIndex: '1202',
        top: 0,
    },
    selectLbl: {
        paddingLeft: 30,
        fontSize: '.75rem',
        position: 'relative',
        bottom: '-20px',
        display: 'unset',
    },
};

export const headerStyle = {
    tabsTop: { backgroundColor: '#343e48', display: 'flex' },
    headerTop: { height: '66px !important', margin: '0 !important' },
    tabRoot: {
        flex: 'auto',
        color: '#b4b7bb',
        '&:focus': {
            color: '#ffffff',
        },
    },
    tabs: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#b4b7bb',
    },
    header: {
        width: '100%',
        background: '#343e48',
        color: 'white',
        display: 'inline-block',
    },
    headerText: {
        width: 91,
        height: 28,
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.4',
        letterSpacing: 'normal',
        color: '#ffffff',
        whiteSpace: 'nowrap',
    },
    headerButton: {
        width: 146,
        height: 41,
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        marginRight: 12,
    },
};

export const distanceSumOnlyDevices = {
    onStarTypeCode: 'onStar',
    fordTypeCode: 'ford',
    calampModelCode: 'calampLMU3640',
};

export const fl360ModelCodes = ['xirgo2500', 'xirgo2580K'];
export const fl360ModelLabels = ['XT2500', 'FL360 KoreOmni'];

export const initialClrState = {
    input1Type: '',
    input1HighName: '',
    input1HighStateName: '',
    input1LowName: '',
    input1LowStateName: '',
    input2Type: '',
    input2HighName: '',
    input2HighStateName: '',
    input2LowName: '',
    input2LowStateName: '',
    input3Type: '',
    input3HighName: '',
    input3HighStateName: '',
    input3LowName: '',
    input3LowStateName: '',
    input4Type: '',
    input4HighName: '',
    input4HighStateName: '',
    input4LowName: '',
    input4LowStateName: '',
    deviceInput1: '',
    deviceInput2: '',
    deviceInput3: '',
    deviceInput4: '',
    deviceInput5: '',
    deviceInput6: '',
    deviceInput7: '',
    deviceInput8: '',
    safetyVisible: false,
    engineHourAdjustmentVal: '',
    engineHourAdjustmentDate: '',
    odometerAdjustmentDate: '',
    odometerAdjustmentVal: '',
    deviceIdProperty: 0,
};

export const initialState = {
    ...initialClrState,
    chipData: [],
    attributeColor: '',
    attributeIcon: '',
    attributeCapacity: '',
    attributeFuelType: '',
    fuelTypes: [
        { value: 'unleaded', label: 'Unleaded' },
        { value: 'diesel', label: 'Diesel' },
    ],
    assetDistanceSum: 0,
    odometerAdjustment: 0,
    odometerAdjustmentHistory: [],
    engineTimeAdjustmentHistory: [],
    fuelCardAccounts: [],
    isColorDisable: false,
    accountId: '',
    accountLabel: '',
    active: true,
    groupId: 0,
    assetIcon: '',
    assetTypeIdProperty: '',
    assetTypeId: '',
    assetTypeLabel: '',
    attributesFlattened: '',
    attributesJSON: '',
    createdBy: '',
    dateCreated: '',
    description: '',
    deviceDescription: '',
    deviceId: 0,
    deviceLabel: '',
    odoRead: 0,
    deviceTypeCode: '',
    deviceModelCode: '',
    deviceName: '',
    deviceProductType: null,
    deviceSerialNumber: '',
    deviceSku: '',
    deviceUniqueId: '',
    eldRequired: false,
    engineTimeAdjustment: 0,
    engineTimeSum: 0,
    externalId: '',
    fuelCardAccountId: '',
    fuelCardId: '',
    fuelCardNumber: '',
    gpsTrackedDistance: 0,
    groupName: '',
    id: '',
    initialEngineTime: '',
    intialHubometer: '',
    intialOdometer: 0,
    internalId: '',
    lastUpdated: '',
    make: '',
    matchId: '',
    model: '',
    assetName: '',
    name: '',
    operatorFirstName: '',
    operatorId: '',
    operatorLastName: '',
    stateTotalDistance: 0,
    stateTotalEngineTime: 0,
    stateTrackedEngineTime: null,
    selectedTag: '',
    tags: '',
    updatedBy: '',
    version: 14,
    vin: '',
    year: '',
    errorMessage: '',
    errorTitle: '',
    globalId: '',
    ELDVisible: false,
    fuelCardVisible: false,
    installationVisible: false,
    deleteDialogOpen: false,
    errorDialogOpen: false,
    successDialogOpen: false,
    loading: true,
    milesTotalHelpOpen: false,
    hoursTotalHelpOpen: false,
    highSpeed: '',
    highSpeedChecked: false,
    highRPM: '',
    highRPMChecked: false,
    hardBraking: false,
    hardAcceleration: false,
    wb1Checked: false,
    wb2Checked: false,
    wb3Checked: false,
    wb4Checked: false,
    selectedFilters: [],
    hardBrakingChecked: 0,
    hardAccelerationChecked: 0,
    attributeNotes: '',
    installationSection: {},
    isFl360DeviceLabel: false,
    sendFL360Commands: true,
};

export const safetyProfileInitialState = {
    highSpeedChecked: false,
    highRPMChecked: false,
    hardBrakingChecked: 0,
    hardAccelerationChecked: 0,
    wb1Checked: false,
    wb2Checked: false,
    wb3Checked: false,
    wb4Checked: false,
    highRPM: '0',
    highSpeed: '0',
};
