/* @flow */
import React from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { getColumnPreferences, getParsedPreferencesValue, getReArrangedTableHeaders } from '../../helper-classes/listUtil';
import {
    assetDataFilterBasedOnDeviceFeature,
    convertDateTime,
    getCorrectDistanceRounded,
    getTemperatureUnitString,
    getTimeFormatAsperUserSettting,
    getUserUnit,
    timeDrivenFormatter,
    isBoolean,
    roundOffToDecimalPlaces,
} from '../../helper-classes/utility-functions';
import { getImage } from '../../util/trip_util';
import GetSvgIcon from '../../util/svgImage_util';

const SUPPRESSED_SPEED_EVENTS = ['AUTO_LOC', 'SLEEP_ENTER', 'IGN_OFF', 'IGN_ON', 'STOPPED', 'MOVE_STOP', 'MOVE_START'];
const COLOR = {
    EVENT_TYPE: {
        MOVING: '#7ed321',
        STOP: '#fc0207',
        IDLE: '#f5a623',
        OTHER: '#000000',
    },
    BATTERY_LEVEL: {
        NA: '#9b9b9b',
        LOW: '#fc0207',
        HIGH: '#7ed321',
    },
    SIGNAL_LEVEL: {
        NA: '#9b9b9b',
        EXCELLENT: '#7ed321',
        WEAK: '#fc0207',
        FAIR: '#f5a623',
        GOOD: '#7ed321',
    },
};
const FL360 = ['xirgo2500', 'xirgo2580K'];

const getAlerts = (event: any) => {
    const { alertSegment } = event;

    if (alertSegment && alertSegment.alerts) {
        const uniqueAlerts = [];

        alertSegment.alerts.forEach((alert) => {
            if (!uniqueAlerts.includes(alert.alertType)) {
                uniqueAlerts.push(alert.alertType);
            }
        });

        return uniqueAlerts.join(', ');
    }

    return '';
};

const getAlertsCell = (event: any) => getAlerts(event);

const getTemperatureCell = (event: any) => {
    const { temperatureSegment } = event;

    if (temperatureSegment
        && temperatureSegment.sensorTemperatures
        && temperatureSegment.sensorTemperatures.length > 0) {
        const sensorProb1 = temperatureSegment.sensorTemperatures.find(sensors => (sensors.sensor === 'PROBE_1'));

        if (sensorProb1 != null && sensorProb1.temperature != null) {
            const isMetric = getUserUnit() === 'metric';
            const tempValue = isMetric ? sensorProb1.temperature :
                ((sensorProb1.temperature * 9) / 5) + 32;

            return (Math.round(tempValue * 10) / 10);
        }
    }

    return '';
};

const isValid = value => (value != null && value !== '' && value !== undefined);

export const getHeading = (event: any) => {
    const { movementSegment } = event;
    let output = '';
    if (movementSegment && isValid(movementSegment.heading)) {
        const { heading } = movementSegment;
        if (heading <= 67.5 || heading >= 292.5) {
            output = 'N';
        } else if (heading >= 112.5 && heading <= 247.5) {
            output = 'S';
        }
        if (heading <= 157.5 && heading >= 22.5) {
            output += 'E';
        } else if (heading <= 337.5 && heading >= 202.5) {
            output += 'W';
        }
    }
    return output;
};

const getHeadingCell = (event: any) => getHeading(event);

const getLocation = (event: any) => {
    const { locationSegment } = event;
    let address = '';

    if (locationSegment && locationSegment.landmark && locationSegment.landmark.landmarkName) {
        address = `${(locationSegment.landmark.landmarkName)}, `;
    }

    if (locationSegment && locationSegment.address) {
        address += `${(locationSegment.address.line1) ? `${locationSegment.address.line1}, ` : ''}${(locationSegment.address.city) ? `${locationSegment.address.city} ` : ''}${(locationSegment.address.stateOrProvince) ? `${locationSegment.address.stateOrProvince}, ` : ''}${(locationSegment.address.postalCode) ? `${locationSegment.address.postalCode} ` : ''}`;
    }

    return address;
};

const getLocationCell = (event: any) => getLocation(event);

export const getSatIcon = (gpsSegment: any, classes: any, isNew: boolean = false) => {
    const { fixStatus = 0, hdop = 0 } = gpsSegment != null ? gpsSegment : {};

    if (fixStatus <= 0) {
        return <Tooltip title="No GPS Signal" disableFocusListener><img src={getImage(isNew ? 'satelliteNoneNew' : 'satelliteNone')} alt="No GPS Signal" className={classes.satIcon} /></Tooltip>;
    } else if (hdop >= 0 && hdop < 3) {
        // good case
        return <Tooltip title="Good GPS Signal" disableFocusListener><img src={getImage(isNew ? 'satelliteGoodNew' : 'satelliteGood')} alt="Good GPS Signal" className={classes.satIcon} /></Tooltip>;
    } else if (hdop >= 3 && hdop < 5) {
        // fair case
        return <Tooltip title="Fair GPS Signal" disableFocusListener><img src={getImage(isNew ? 'satelliteFairNew' : 'satelliteFair')} alt="Fair GPS Signal" className={classes.satIcon} /></Tooltip>;
    } else if (hdop >= 5) {
        // bad case
        return <Tooltip title="Poor GPS Signal" disableFocusListener><img src={getImage(isNew ? 'satellitePoorNew' : 'satellitePoor')} alt="Poor GPS Signal" className={classes.satIcon} /></Tooltip>;
    }
    // any other value
    return <Tooltip title="No GPS Signal" disableFocusListener><img src={getImage(isNew ? 'satelliteNoneNew' : 'satelliteNone')} alt="No GPS Signal" className={classes.satIcon} /></Tooltip>;
};

const getEventName = (event: any) => {
    if (event.eventTypeCode === 'STOPPED') {
        return `Stopped for ${timeDrivenFormatter(event.duration)}`;
    } else if (event.eventTypeCode === 'IDLE_PER') {
        return `Idled for ${timeDrivenFormatter(event.duration)}`;
    }

    return event.eventName;
};

export const getEventNameCell = (event: any) => {
    const { eventTypeCode } = event;
    let color = COLOR.EVENT_TYPE.OTHER;

    if (eventTypeCode === 'MOVE_PER') {
        color = COLOR.EVENT_TYPE.MOVING;
    } else if (eventTypeCode === 'STOPPED' || eventTypeCode === 'MOVE_STOP') {
        color = COLOR.EVENT_TYPE.STOP;
    // EventTypeCode as Idle, for UnSquash events from Snowflake. To displays as Idle
    } else if (eventTypeCode === 'IDLE_PER' || eventTypeCode === 'Idle') {
        color = COLOR.EVENT_TYPE.IDLE;
    }

    return { name: getEventName(event) || '', color };
};

const getEventTypeCell = (event: any, classes: any) => {
    const eventNameData = getEventNameCell(event);
    const cell = (
        <React.Fragment>
            <Grid container>
                <Grid item xs={2}>
                    {getSatIcon(event.gpsSegment, classes)}
                </Grid>
                <Grid item xs={10}>
                    <Tooltip title={eventNameData.name} disableFocusListener>
                        <Typography noWrap style={{ color: eventNameData.color }}>
                            {eventNameData.name}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>
    );

    return cell;
};

export const getBatteryIconTooltipText = (batteryVoltage: any) => {
    let batteryIconTooltipText = 'Not Reporting';
    let color = COLOR.BATTERY_LEVEL.NA;

    if (batteryVoltage == null) {
        color = COLOR.BATTERY_LEVEL.NA;
        batteryIconTooltipText = 'No Info';
    } else if (batteryVoltage < 11.6) {
        color = COLOR.BATTERY_LEVEL.LOW;
        batteryIconTooltipText = 'Weak';
    } else if (batteryVoltage >= 11.6) {
        color = COLOR.BATTERY_LEVEL.HIGH;
        batteryIconTooltipText = 'Good';
    }

    return { batteryIconTooltipText, color };
};


export const getBatteryIcon = (batteryVoltage: any, classes: any, newIcon: boolean = false) => {
    if (batteryVoltage == null) {
        return <img src={getImage(newIcon ? 'batteryNotReportingNew' : 'batteryNotReporting')} alt="Not Reporting" className={classes.satIcon} />;
    } else if (batteryVoltage < 11.6) {
        return <img src={getImage(newIcon ? 'batteryLowNew' : 'batteryLow')} alt="Weak" className={classes.satIcon} />;
    } else if (batteryVoltage >= 11.6) {
        return <img src={getImage(newIcon ? 'goodBatteryNew' : 'goodBattery')} alt="Good" className={classes.satIcon} />;
    }
    // any other value
    return <img src={getImage(newIcon ? 'batteryNotReportingNew' : 'batteryNotReporting')} alt="Not Reporting" className={classes.satIcon} />;
};

const getBatteryCell = (event: any, classes: any) => {
    const batteryVoltage = event.batterySegment ? event.batterySegment.externalVoltage : null;
    const { batteryIconTooltipText, color } = getBatteryIconTooltipText(batteryVoltage);
    const batteryVoltageText = batteryVoltage == null ? '-' : `${(Math.round(batteryVoltage * 10.0) / 10.0)}v`;

    const cell = (
        <React.Fragment>
            <Grid container>
                <Tooltip title={batteryIconTooltipText} disableFocusListener>
                    <Grid item xs={2}>
                        {getBatteryIcon(batteryVoltage, classes)}
                    </Grid>
                </Tooltip>
                <Tooltip title={batteryVoltageText} disableFocusListener>
                    <Grid item xs={10}>
                        <Typography noWrap style={{ color }}>
                            {batteryVoltageText}
                        </Typography>
                    </Grid>
                </Tooltip>
            </Grid>
        </React.Fragment>
    );

    return cell;
};

export const getCellSignalIcon = (rssi: any, classes: any) => {
    if (rssi == null || rssi === undefined) {
        return '';
    } else if (rssi >= -55) {
        return <img src={getImage('excellentCellular')} alt="Excellent" className={classes.satIcon} />;
    } else if (rssi <= -110) {
        return <img src={getImage('badCellular')} alt="Weak" className={classes.satIcon} />;
    } else if (rssi < -86 && rssi >= -110) {
        return <img src={getImage('fairCellular')} alt="Fair" className={classes.satIcon} />;
    } else if (rssi < -55 && rssi >= -86) {
        return <img src={getImage('goodCellular')} alt="Good" className={classes.satIcon} />;
    }
    // any other value
    return '';
};

export const getCellSignalText = (rssi: any) => {
    let color = COLOR.SIGNAL_LEVEL.NA;
    let cellSignalText = 'Unknown';

    if (rssi == null) {
        color = COLOR.SIGNAL_LEVEL.NA;
        cellSignalText = 'Unknown';
    } else if (rssi >= -55) {
        color = COLOR.SIGNAL_LEVEL.EXCELLENT;
        cellSignalText = 'Excellent';
    } else if (rssi <= -110) {
        color = COLOR.SIGNAL_LEVEL.WEAK;
        cellSignalText = 'Weak';
    } else if (rssi < -86 && rssi >= -110) {
        color = COLOR.SIGNAL_LEVEL.FAIR;
        cellSignalText = 'Fair';
    } else if (rssi < -55 && rssi >= -86) {
        color = COLOR.SIGNAL_LEVEL.GOOD;
        cellSignalText = 'Good';
    }

    return { cellSignalText, color };
};

const getCellSignalCell = (event: any, classes: any) => {
    const rssi = event.networkSegment ? event.networkSegment.rssi : null;
    const { cellSignalText, color } = getCellSignalText(rssi);

    const cell = (
        <React.Fragment>
            <Tooltip title={cellSignalText} disableFocusListener>
                <Grid container>
                    <Grid item xs={2}>
                        {getCellSignalIcon(rssi, classes)}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography noWrap style={{ color }}>
                            {cellSignalText}
                        </Typography>
                    </Grid>
                </Grid>
            </Tooltip>
        </React.Fragment>
    );

    return cell;
};

export const getSpeedCell = (event: any) => {
    const { eventTypeCode } = event;

    if (SUPPRESSED_SPEED_EVENTS.some(type => type === eventTypeCode)) {
        return 0;
    }

    return event.movementSegment && event.movementSegment.speed != null ? getCorrectDistanceRounded(event.movementSegment.speed, 'mi') : '';
};

export const getSpeedLimitCell = (event: any) => (event.movementSegment && event.movementSegment.speedLimit != null ? getCorrectDistanceRounded(event.movementSegment.speedLimit, 'mi') : '');

const getColor = level => ((level && level === 'LOW') ? '#fc0207' : '#7ed321');

const getTireColor = status => ((status && status.toLowerCase() === 'default') ? '#9B9B9B' : getColor(status));

const getInnerGrid = (xs, color, value) => (
    <Grid item xs={xs}>
        <span
            style={{ color, fontSize: 14 }}
        >
            {value}
        </span>
    </Grid>
);

const getInnerGridWithSlash = (xs, color, value, classes) => (
    <Grid item xs={xs}>
        <div
            className={classes.tirePressure}
            style={{ color, fontSize: 14 }}
        >
            {value}
            <div style={{ paddingLeft: 1 }}>&#47;</div>
        </div>
    </Grid>
);

const getCommonTemp = (data, classes, width) => (
    <React.Fragment>
        <Tooltip title={data.title} disableFocusListener>
            <Grid container className={classes.textContent} style={{ maxWidth: width }}>
                <Grid item xs={5}>
                    <GetSvgIcon type={data.type} fillcolor={data.color} style={data.style || {}} />
                </Grid>
                {getInnerGrid(7, data.color, data.value)}
            </Grid>
        </Tooltip>
    </React.Fragment>
);

const getSeatBeltStatusCell = (event: any, classes: any, seatBeltRequired: boolean) => {
    let seatBeltBuckledStatus = 'N/A';
    let cell = seatBeltBuckledStatus;

    if (seatBeltRequired && event.seatBeltSegment
        && event.seatBeltSegment.seatBeltStatuses
        && event.seatBeltSegment.seatBeltStatuses.length > 0) {
        const driverSeatBeltStatus = event.seatBeltSegment.seatBeltStatuses.find(enableSeatBelt => (enableSeatBelt.seat === 'DRIVER'));

        if (driverSeatBeltStatus) {
            seatBeltBuckledStatus = driverSeatBeltStatus.buckledStatus;
        }

        if (seatBeltBuckledStatus === 'BUCKLED' || seatBeltBuckledStatus === 'UNBUCKLED') {
            const color = getColor((seatBeltBuckledStatus === 'UNBUCKLED') ? 'LOW' : 'GOOD');
            const value = seatBeltBuckledStatus === 'BUCKLED' ? 'ON' : 'OFF';
            const data = {
                title: value,
                type: 'seatbelt',
                value,
                color,
                style: { width: 11, height: 12 },
            };
            const maxWidth = 45;
            cell = getCommonTemp(data, classes, maxWidth);
        }
    }

    return cell;
};

const getFuelTankLevelCell = (event: any, classes: any, enableFuelLevel) => {
    let cell = 'N/A';

    if (enableFuelLevel && event.fuelSegment && event.fuelSegment.fuelLevel != null) {
        const fuelValue = Math.round(event.fuelSegment.fuelLevel);
        const color = getColor(fuelValue <= 10 ? 'LOW' : 'GOOD');
        const value = `${fuelValue}%`;
        const data = {
            title: value,
            type: 'fuel-level',
            value,
            color,
            style: { width: 14, height: 12 },
        };
        const maxWidth = 46;
        cell = getCommonTemp(data, classes, maxWidth);
    }

    return cell;
};

const getTirePressureStatus = (pressure, placard, deviceModelCode) => {
    if (FL360.includes(deviceModelCode) && placard == null) return 'DEFAULT';
    const pressurePsi = Math.round(pressure * 0.145038);
    const placardPsi = placard * 0.145038;
    const diff = Math.abs((placardPsi - pressurePsi) / placardPsi);

    if (diff > 0.20) {
        return 'LOW';
    }

    return 'GOOD';
};

const isValidTirePressure = (tirePressure, deviceModelCode) => (tirePressure
        && isValid(tirePressure.pressure)
        && (isValid(tirePressure.placardValue) || FL360.includes(deviceModelCode)));

const kpaToPsiAndRound = (kpa) => {
    const kpaToPsi = 0.145038;
    return Math.round(kpa * kpaToPsi);
};

const getTireStatusColor = (
    tirePressureFLStatus, tirePressureFRStatus,
    tirePressureRLStatus, tirePressureRRStatus,
) => {
    if (tirePressureFLStatus === 'LOW') {
        return tirePressureFLStatus;
    }
    if (tirePressureFRStatus === 'LOW') {
        return tirePressureFRStatus;
    }
    if (tirePressureRLStatus === 'LOW') {
        return tirePressureRLStatus;
    }
    return tirePressureRRStatus;
};

const getTirePressureCell = (
    event: any,
    classes: any,
    enableTirePressure: boolean,
    deviceModelCode: string,
) => {
    const { tirePressureSegment } = event;

    let cell = 'N/A';

    if (enableTirePressure && tirePressureSegment
        && tirePressureSegment.tirePressures
        && tirePressureSegment.tirePressures.length > 0) {
        const tirePressureFL = tirePressureSegment.tirePressures.find(tirePressure => (tirePressure.position === 'FRONT_LEFT'));
        const tirePressureFR = tirePressureSegment.tirePressures.find(tirePressure => (tirePressure.position === 'FRONT_RIGHT'));
        const tirePressureRL = tirePressureSegment.tirePressures.find(tirePressure => (tirePressure.position === 'REAR_LEFT'));
        const tirePressureRR = tirePressureSegment.tirePressures.find(tirePressure => (tirePressure.position === 'REAR_RIGHT'));

        if (isValidTirePressure(tirePressureFL, deviceModelCode)
            && isValidTirePressure(tirePressureFR, deviceModelCode)
            && isValidTirePressure(tirePressureRL, deviceModelCode)
            && isValidTirePressure(tirePressureRR, deviceModelCode)
        ) {
            const tirePressureFLStatus = getTirePressureStatus(
                tirePressureFL.pressure,
                tirePressureFL.placardValue,
                deviceModelCode,
            );

            const tirePressureFRStatus = getTirePressureStatus(
                tirePressureFR.pressure,
                tirePressureFR.placardValue,
                deviceModelCode,
            );
            const tirePressureRLStatus = getTirePressureStatus(
                tirePressureRL.pressure,
                tirePressureRL.placardValue,
                deviceModelCode,
            );

            const tirePressureRRStatus = getTirePressureStatus(
                tirePressureRR.pressure,
                tirePressureRR.placardValue,
                deviceModelCode,
            );

            cell = (
                <React.Fragment>
                    <Grid
                        container
                        className={classes.tirePressure}
                        style={{ marginLeft: -5, width: 145 }}
                    >
                        <Grid item xs={2} className={classes.tirePressure}>
                            <GetSvgIcon style={{ width: 12 }} type="tire-pressure" fillcolor={getTireColor(getTireStatusColor(tirePressureFLStatus, tirePressureFRStatus, tirePressureRLStatus, tirePressureRRStatus))} />
                        </Grid>
                        {
                            (
                                <React.Fragment>
                                    {
                                        getInnerGridWithSlash(
                                            2,
                                            getTireColor(tirePressureFLStatus),
                                            kpaToPsiAndRound(tirePressureFL.pressure),
                                            classes,
                                        )
                                    }
                                    {
                                        getInnerGridWithSlash(
                                            2,
                                            getTireColor(tirePressureFRStatus),
                                            kpaToPsiAndRound(tirePressureFR.pressure),
                                            classes,
                                        )
                                    }
                                    {
                                        getInnerGridWithSlash(
                                            2,
                                            getTireColor(tirePressureRLStatus),
                                            kpaToPsiAndRound(tirePressureRL.pressure),
                                            classes,
                                        )
                                    }
                                    {
                                        getInnerGrid(
                                            2,
                                            getTireColor(tirePressureRRStatus),
                                            kpaToPsiAndRound(tirePressureRR.pressure),
                                        )
                                    }
                                </React.Fragment>
                            )}
                        <Grid item xs={2} />
                    </Grid>
                </React.Fragment>
            );
        }
    }

    return cell;
};

const getOilLifeCell = (event, classes, enableOilLifeRemaining: boolean) => {
    let cell = 'N/A';

    if (enableOilLifeRemaining && event.oilSegment && event.oilSegment.oilLife != null) {
        const oilLifeValue = Math.round(event.oilSegment.oilLife);
        const color = getColor(oilLifeValue < 20 ? 'LOW' : 'GOOD');
        const value = `${oilLifeValue}%`;
        const data = {
            title: value,
            type: 'oil-life',
            value,
            color,
            style: { width: 22, height: 12 },
        };
        const maxWidth = 65;
        cell = getCommonTemp(data, classes, maxWidth);
    }

    return cell;
};

const getListHeader = (
    userPreferences: any,
    classes: any,
    assetFeatures: any,
    deviceModelCode: string,
) => {
    const preferences = getParsedPreferencesValue(userPreferences);
    const enableSeatBelt = assetDataFilterBasedOnDeviceFeature('seatBeltStatus', assetFeatures);
    const enableTirePressure = assetDataFilterBasedOnDeviceFeature('tirePressure', assetFeatures);
    const enableOilLifeRemaining = assetDataFilterBasedOnDeviceFeature('oilLifeRemaining', assetFeatures);
    const enableFuelLevel = assetDataFilterBasedOnDeviceFeature('fuelLevelStatus', assetFeatures);
    const enableTemperature = assetDataFilterBasedOnDeviceFeature('fridgeStatus', assetFeatures);
    const columns = [
        {
            eventDate: 'Date',
            ...getColumnPreferences('eventDate', preferences),
            customMarkup: event => (event.eventDate ? convertDateTime(event.eventDate, 'MM/DD/YY') : ''),
        },
        {
            eventTime: 'Time',
            ...getColumnPreferences('eventTime', preferences),
            customMarkup: event => (event.eventDate ? convertDateTime(event.eventDate, (getTimeFormatAsperUserSettting() === '12h') ? 'h:mm A' : 'H:mm') : ''),
        },
        {
            eventName: 'Event Type',
            ...getColumnPreferences('eventName', preferences),
            customMarkup: event => getEventTypeCell(event, classes),
        },
        {
            location: 'Location',
            ...getColumnPreferences('location', preferences),
            customMarkup: getLocationCell,
        },
        {
            speed: 'Speed',
            ...getColumnPreferences('speed', preferences),
            customMarkup: getSpeedCell,
        },
        {
            speedLimit: 'Road Speed',
            ...getColumnPreferences('speedLimit', preferences),
            customMarkup: getSpeedLimitCell,
        },
        {
            heading: 'Heading',
            ...getColumnPreferences('heading', preferences),
            customMarkup: getHeadingCell,
        },
        {
            driver: 'Driver',
            ...getColumnPreferences('driver', preferences),
            customMarkup: event => (event.operatorSegment ? `${event.operatorSegment.operatorFirstName} ${event.operatorSegment.operatorLastName}` : ''),
        },
        {
            alerts: 'Alerts',
            ...getColumnPreferences('alerts', preferences),
            customMarkup: getAlertsCell,
        },
        {
            batteryVoltage: 'Asset Battery',
            ...getColumnPreferences('batteryVoltage', preferences),
            customMarkup: event => getBatteryCell(event, classes),
        },
        {
            cargoLoaded: 'Cargo Status',
            ...getColumnPreferences('cargoLoaded', preferences),
            disableSort: true,
            customMarkup: data => {
                if (data.cargoSegment && isBoolean(data.cargoSegment.loaded)) {
                    return data.cargoSegment.loaded ? 'Loaded' : 'Unloaded'
                } else return '--';
            },
        },
        {
            powerConnected: 'Tractor Power',
            ...getColumnPreferences('powerConnected', preferences),
            disableSort: true,
            customMarkup: data => {
                if (data.powerSegment && isBoolean(data.powerSegment.connected)) {
                    return data.powerSegment.connected ? 'Connected' : 'Disconnected'
                } else return '--';
            },
        },
        {
            solarVoltage: 'Solar Voltage',
            ...getColumnPreferences('solarVoltage', preferences),
            customMarkup: data => {
                if (data.powerSegment && data.powerSegment.solarVoltage != null) {
                    return `${roundOffToDecimalPlaces(data.powerSegment.solarVoltage, 2)}v`;
                } else return '--';
            },
        },
        {
            rssi: 'Cell Signal',
            ...getColumnPreferences('rssi', preferences),
            customMarkup: event => getCellSignalCell(event, classes),
        },
        {
            seatBeltStatus: 'Seatbelt Status',
            ...getColumnPreferences(
                'seatBeltStatus',
                preferences,
                {
                    show: enableSeatBelt,
                },
            ),
            showInDropDown: enableSeatBelt,
            customMarkup: event => getSeatBeltStatusCell(event, classes, enableSeatBelt),
        },
        {
            fuelTankLevel: 'Fuel Tank Level',
            ...getColumnPreferences(
                'fuelTankLevel',
                preferences,
                {
                    show: enableFuelLevel,
                },
            ),
            showInDropDown: enableFuelLevel,
            customMarkup: event => getFuelTankLevelCell(event, classes, enableFuelLevel),
        },
        {
            tirePressure: 'Tire Pressure',
            ...getColumnPreferences(
                'tirePressure',
                preferences,
                {
                    show: enableTirePressure,
                },
            ),
            disableSort: true,
            showInDropDown: enableTirePressure,
            customMarkup: event => getTirePressureCell(
                event,
                classes,
                enableTirePressure,
                deviceModelCode,
            ),
        },
        {
            oilLifeRemaining: 'Oil Life Remaining',
            ...getColumnPreferences(
                'oilLifeRemaining',
                preferences,
                {
                    show: enableOilLifeRemaining,
                },
            ),
            showInDropDown: enableOilLifeRemaining,
            customMarkup: event => getOilLifeCell(event, classes, enableOilLifeRemaining),
        },
        {
            temperature: `Temperature (${getTemperatureUnitString()})`,
            ...getColumnPreferences(
                'temperature',
                preferences,
                {
                    show: enableTemperature,
                },
            ),
            showInDropDown: enableTemperature,
            customMarkup: getTemperatureCell,
        },
        {
            address: 'Address',
            ...getColumnPreferences('address', preferences),
            customMarkup: event => (event.locationSegment && event.locationSegment.address ? event.locationSegment.address.line1 : ''),
        },
        {
            city: 'City',
            ...getColumnPreferences('city', preferences),
            customMarkup: event => (event.locationSegment && event.locationSegment.address ? event.locationSegment.address.city : ''),
        },
        {
            state: 'State',
            ...getColumnPreferences('state', preferences),
            customMarkup: event => (event.locationSegment && event.locationSegment.address ? event.locationSegment.address.stateOrProvince : ''),
        },
        {
            lat: 'Latitude',
            ...getColumnPreferences('lat', preferences),
            customMarkup: event => (event.locationSegment ? event.locationSegment.lat : ''),
        },
        {
            lng: 'Longitude',
            ...getColumnPreferences('lng', preferences),
            customMarkup: event => (event.locationSegment ? event.locationSegment.lng : ''),
        },
    ];

    if (preferences) {
        return getReArrangedTableHeaders(columns);
    }

    return columns;
};

const getValue = (object, propertyName) => {
    const parts = propertyName.split('.');
    const { length } = parts;
    let i;
    let property = object || this;

    for (i = 0; i < length; i += 1) {
        if (property == null) {
            return '';
        }
        property = property[parts[i]];
    }

    return property;
};

const getSortColumn = (orderBy: string) => {
    switch (orderBy) {
    case 'eventTime': return 'eventDate';
    case 'lat': return 'locationSegment.lat';
    case 'lng': return 'locationSegment.lng';
    case 'address': return 'locationSegment.address.line1';
    case 'city': return 'locationSegment.address.city';
    case 'state': return 'locationSegment.address.stateOrProvince';
    case 'speed': return 'movementSegment.speed';
    case 'speedLimit': return 'movementSegment.speedLimit';
    case 'batteryVoltage': return 'batterySegment.externalVoltage';
    case 'oilLifeRemaining': return 'oilSegment.oilLife';
    case 'fuelTankLevel': return 'fuelSegment.fuelLevel';
    case 'solarVoltage': return 'powerSegment.solarVoltage';
    default: return orderBy;
    }
};

const getSeatBeltStatusForSort = (event: any) => {
    let seatBeltBuckledStatus = '0';
    if (event.seatBeltSegment
        && event.seatBeltSegment.seatBeltStatuses
        && event.seatBeltSegment.seatBeltStatuses.length > 0) {
        const driverSeatBeltStatus = event.seatBeltSegment.seatBeltStatuses.find(enableSeatBelt => (enableSeatBelt.seat === 'DRIVER'));
        if (driverSeatBeltStatus) {
            seatBeltBuckledStatus = driverSeatBeltStatus.buckledStatus === 'BUCKLED' ? '1' : '0';
        }
    }
    return seatBeltBuckledStatus;
};

const getDriverForSort = (event: any) => (event.operatorSegment ? `${event.operatorSegment.operatorFirstName} ${event.operatorSegment.operatorLastName}` : '');

const getCellSignalForSort = (event: any) => {
    const rssi = event.networkSegment ? event.networkSegment.rssi : null;

    let cellSignalText = 'Unknown';
    if (rssi == null) {
        cellSignalText = 'Unknown';
    } else if (rssi >= -55) {
        cellSignalText = 'Excellent';
    } else if (rssi <= -110) {
        cellSignalText = 'Weak';
    } else if (rssi < -86 && rssi >= -110) {
        cellSignalText = 'Fair';
    } else if (rssi < -55 && rssi >= -86) {
        cellSignalText = 'Good';
    }

    return cellSignalText;
};

export const sortEvents = (events: any, order: string, orderBy: string) => {
    events.sort((a, b) => {
        let valueA = '';
        let valueB = '';

        switch (orderBy) {
        case 'location': {
            valueA = getLocation(a);
            valueB = getLocation(b);
            break;
        }
        case 'eventName': {
            valueA = getEventName(a);
            valueB = getEventName(b);
            break;
        }
        case 'alerts': {
            valueA = getAlerts(a);
            valueB = getAlerts(b);
            break;
        }
        case 'heading': {
            valueA = getHeading(a);
            valueB = getHeading(b);
            break;
        }
        case 'driver': {
            valueA = getDriverForSort(a);
            valueB = getDriverForSort(b);
            break;
        }
        case 'rssi': {
            valueA = getCellSignalForSort(a);
            valueB = getCellSignalForSort(b);
            break;
        }
        case 'seatBeltStatus': {
            valueA = getSeatBeltStatusForSort(a);
            valueB = getSeatBeltStatusForSort(b);
            break;
        }
        default: {
            const orderByCol = getSortColumn(orderBy);
            valueA = getValue(a, orderByCol);
            valueB = getValue(b, orderByCol);
            break;
        }
        }

        if (order === 'desc') {
            if (valueA < valueB) {
                return -1;
            } else if (valueA > valueB) {
                return 1;
            }
            return 0;
        }
        if (valueB < valueA) {
            return -1;
        } else if (valueB > valueA) {
            return 1;
        }
        return 0;
    });
    return events;
};

export default getListHeader;
